export const CANCEL_CONFIRMATION = 'This action will cancel the Assessment Request and progress will be deleted. Are you sure?';
export const SCOPING_CANCEL_CONFIRMATION =
  'This action will remove the scoping and security information page and will cancel the Assessment Request. Are you sure?';
export const SECTOR_TOOLTIP = 'Add your associated business units';
export const ASSESSMENT_ID_TOOLTIP = 'Add an Assessment identifier generated from another system';
export const CLIENT_VENDOR_ID_TOOLTIP = 'Add a Vendor identifier generated from another system';
export const FINAL_SUBMISSION_QUESTION = 'Are you sure you want to cancel Assessment Request?';
export const ASSESSMENT_REQUEST_FINAL_SUBMISSION_SUCCESS = 'Assessment Request Saved successfully';
export const REMOVE_SELECTED_FILE_FROM_CREATE_ASSESSMENT_REQUEST = 'This action will remove the selected file from assessment request form. Are you sure?';

export const ASSESSMENT_REQUEST_STEP_ONE = 1;
export const ASSESSMENT_REQUEST_STEP_TWO = 2;
export const ASSESSMENT_REQUEST_STEP_THREE = 3;
export const ASSESSMENT_REQUEST_STEP_FOUR = 4;
export const ASSESSMENT_REQUEST_STEP_FIVE = 5;
export const ASSESSMENT_REQUEST_STEP_SIX = 6;
export const ASSESSMENT_REQUEST_STEP_SEVEN = 7;
export const ASSESSMENT_REQUEST_SEC_CERT = 8;
export const ASSESSSMENT_REQUEST_MAX_FILE_SIZE_LIMIT = 50; //In MB
export const ASSESSMENT_CATEGORY_ID = '1';
export const ASSESSMENT_TYPE_ID = '1';

export const STEP_ONE_FORM_FIELDS = {
  AssessmentRequestCategoryId: null,
  AssessmentRequestTypeId: null,
};
export const STEP_TWO_FORM_FIELDS = {
  VendorOrgName: null,
  VendorUrl: null,
  VendorHeadquarterCountry: null,
  VendorState: null,
  VendorContactFirstName: null,
  VendorContactLastName: null,
  VendorContactEmail: null,
  VendorContactTitle: null,
  VendorContactPhone: null,
  ProductCategoryId: null,
  IsProductMedicalDevice: null,
  ProductName: null,
  ProductVersion: null,
  ProductUrl: null,
  ProductDescription: null,
  Sectors: null,
  InternalAssessmentId: null,
  InternalClientVendorId: null,
};
export const STEP_THREE_FORM_FIELDS = {
  ScopingInformationId: null,
  SecurityControlInformationId: null,
};
export const STEP_FOUR_FORM_FIELDS = {
  StakeholderContactFirstName: null,
  StakeholderContactLastName: null,
  StakeholderContactDepartment: null,
  StakeholderContactEmail: null,
  StakeholderContactPhone: null,
};
export const STEP_SIX_FORM_FIELDS = {
  Comment: null,
};

export const URL_VALIDATION_ERROR = 'Please enter a valid website URL. eg: http://www.corltech.com';

export const NOT_RESPONSE_QUESTIONS = null;
export const NOT_IS_SUCCESS = false;

export const CORL_CLEARED = 'Corl Cleared';
export const IF_CORL_CLEARED = 'YES, THIS IS CORLCLEARED';
export const IF_NON_CORL_CLEARED = 'NO';
export const REGULAR_ASSESSMENT_LABEL = "REGULAR";
export const PRE_ASSESSMENT_LABEL = "PRE-ASSESSMENT";
export const FULL_ASSESSMENT = 'Full Assessment';
export const PRE_ASSESSMENT = 'Pre-Assessment';
export const BOTH = 'Both';

export const MULTI_SELECT_RESPONSE_ID = 3;

export const DEFAULT_REGISTERED_BUSINESS_ADDRESS_COUNTRY = 'United States';
export const HEALTHCARE = 1;

export const ASSESSMENT_REQUEST_SCOPING_INFORMATION_ID = '2';
export const ASSESSMENT_REQUEST_SECURITY_CONTROL_INFORMATION_ID = '2';
export const ASSESSMENT_REQUEST_FILE = 'Assessment Request File';
export const ASSESSMENT_REQUEST_SCOPING_FILE = 'Assessment Request Scoping File';
export const ASSESSMENT_REQUEST_SECURITY_FILE = 'Assessment Request Security File';

export const FULL_ASSESSMENT_VALUE = 1;
export const PREASSESSMENT_VALUE = 2;
export const CORL_CLEARED_YES = 1;
export const CORL_CLEARED_NO = 2;
export const IS_PREASSESSMENT = 1;
export const BOTH_VALUE = 3;

export const SCOPING_YES = 1;
export const SCOPING_NO = 2;
export const IF_SCOPING_YES = 'CORL to contact Vendor';
export const IF_SCOPING_NO = "We'll provide the Scope";

export const SECURITY_YES = 1;
export const SECURITY_NO = 2;
export const IF_SECURITY_YES = 'CORL to contact Vendor';
export const IF_SECURITY_NO = "We'll provide the Security Information";

export const MEDICAL_NO = 0;
export const MEDICAL_YES = 1;
export const IF_MEDICAL_NO = 'No';
export const IF_MEDICAL_YES = 'Yes';

export const ROUTE_LEAVING_GUARD_CONFIRMATION = 'Are you sure you want to leave Assessment Request ?';
export const CANCEL = 'Cancel';

export const CREATE_SUPPORT_TICKET_SUCCESS = 'Support Ticket Created Successfully';

export const NO_VENDOR_PRODUCTS_FOUND_MESSAGE = 'No Vendor Products Found';

export const NO_VENDOR_PRODUCTS_FOUND_OPTION = {
  value: 0,
  label: NO_VENDOR_PRODUCTS_FOUND_MESSAGE,
  disabled: true,
};
export const VALIDATED_ASSESSMENT = 1;
export const UNVALIDATED_ASSESSMENT = 2;
export const BOTH_VALIDATION_PREFERENCE = 3;
export const UNVALIDATED = 'unvalidated';
export const VALIDATED_ASSESSMENT_LABEL = 'Validated Assessment';
export const UNVALIDATED_ASSESSMENT_LABEL = 'Unvalidated Assessment';

export const CLIENT_ACCEPTED_CORL_CLEARED_PRODUCT_SELECTION_HELPER_TEXT =
  'This Product is CORLCleared and the results will be delivered faster than a normal assessment.';
export const CLIENT_REJECTED_CORL_CLEARED_PRODUCT_SELECTION_HELPER_TEXT =
  "This Product is CORLCleared which means we could have assessment results ready for you quicker. Click on 'AGREE' to proceed as CORLCleared Assessment or 'DISAGREE' to proceed as Standard Assessment.";
export const ASSESSMENT_TYPE_CHANGED_TO_CORL_CLEARED_TOASTER_MESSAGE = 'Assessment type changed to CORLCleared Assessment.';

export const AGREE = 'Agree';
export const DISAGREE = 'Disagree';

export const CORL_CLEARED_ASSESSMENT_REQUEST_TYPE = '1';
export const STANDARD_ASSESSMENT_REQUEST_TYPE = '2';

export const CONTRACT_CONFIGURATION_SECURITY_CERTIFICATION_NEVER = 1;
export const CONTRACT_CONFIGURATION_SECURITY_CERTIFICATION_SOMETIMES = 2;
export const CONTRACT_CONFIGURATION_SECURITY_CERTIFICATION_ALWAYS = 3;

export const SEC_CER_YES_VALUE = '1';
export const SEC_CER_NO_VALUE = '0';

export const SECURITY_CERTIFICATE_OPTIONS = [
  { value: SEC_CER_YES_VALUE, label: 'Yes' },
  { value: SEC_CER_NO_VALUE, label: 'No' },
];

export const SEC_CERT_ALWAYS_VALUE = 3;
export const SEC_CERT_SOMETIMES_VALUE = 2;
export const SEC_CERT_NEVER_VALUE = 1;

export const ACCEPT_SEC_CERT_ENABLED = "Enabled";
export const ACCEPT_SEC_CERT_DISABLED = "Disabled";
export const PRE_ASSESSMENT_REQUEST_TYPE = '4';