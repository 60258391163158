import { faCircleInfo, faShield, faGlobe, faHand, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { faNotes } from '@fortawesome/pro-solid-svg-icons';

export const ASSESSMENT_OVERVIEW_TAB_OPTIONS = {
  BOARD: 'BOARD',
  TABLE: 'TABLE',
  QUEUE: 'QUEUE',
};

export const QUICK_DETAILS_TOGGLES = [
  {
    label: 'DETAILS',
    value: '1',
    icons: faCircleInfo,
  },
  {
    label: 'NOTES',
    value: '2',
    icons: faNotes,
    flip: 'vertical',
  },
];

export const SELECTED_VALUE_DETAILS = 1;

export const SCOPING_QUESTIONNAIRE = [
  {
    productName: 'IVPQ v1.5',
    progress: '145/145',
    status: 'Complete',
  },
];
export const SECURITY_QUESTIONNAIRE = [
  {
    productName: 'Backoffice VSQ',
    progress: '100/100',
    status: 'Submitted',
  },
  {
    productName: 'Cloud PSQ',
    progress: '14/98',
    status: 'In-progress',
  },
  {
    productName: 'Device PSQ',
    progress: '0/45',
    status: 'Requested',
  },
];
/* Needs to be updated during API integration  */
export const REQUESTED = 'requested';
export const SUBMITTED = 'submitted';
export const IN_PROGRESS = 'in_progress';
export const COMPLETED = 'complete';
export const UPDATE = 'update';

export const DISABLE = 'Disable';
export const ENABLE = 'Enable';

export const EXECUTIVE_SUMMARY_ENABLED = 2;

export const ASSESSMENT_LIST_TABLE_DEFAULT_FILENAME = 'AssessmentsList';

export const THRESHOLD = '1';
export const THRESHOLD_DESCRIPTION = 'CORL pre-assessment';

export const ESCALATED = 'escalated';
export const ASSESSMENT_COMPLETED = 'completed';

export const LANE = 1;
export const SUBCOUNT = 2;
export const DEFAULT_ASSESSMENT_QUEUE_PAGE_NO = 1;
export const DEFAULT_ASSESSMENT_QUEUE_ROWS_PER_PAGE = 10;
export const QUEUED_ASSESSMENTS_ALERT_MESSAGE = 'Order Number cannot be more than the number of Queued Assessments';

//vendor metrics - waiting on vendor to answer questionnaire status master step constants
export const SCOPING_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE = 'Scoping Questionnaire: Awaiting vendor response\r\n';
export const SECURITY_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE = 'Security Questionnaire: Awaiting vendor response\r\n';
export const GAP_VALIDATION_AWAITING_VENDOR_RESPONSE = 'Gap Validation: Awaiting vendor response\r\n';
export const INHERENT_RISK_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE = '';

export const VENDOR_METRICS_STATUS_MASTER_STEPS = [
  SCOPING_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE,
  SECURITY_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE,
  GAP_VALIDATION_AWAITING_VENDOR_RESPONSE,
  INHERENT_RISK_QUESTIONNAIRE_AWAITING_VENDOR_RESPONSE
]

export const REGULAR_ASSESSMENT = "regular";
export const STANDARD_ASSESSMENT = "Standard";
export const CORL_CLEARED_ASSESSMENT = "CORL Cleared";

export const ASSESSMENT_EXPEDITE_LABEL_VALUE = 2;
export const ASSESSMENT_ESCALATED_LABEL_VALUE = 3;
export const ASSESSMENT_PAUSE_LABEL_VALUE = 4;

export const IS_CORL_CLEARED = 1;

export const QUEUED_FOR_AUDIT = 22;
export const CORL_AUDITOR_VALIDATION = 23;


export const ASSESSMENT_RESULTS_READY_FOR_REVIEW_STATUS = 25;
export const ASSESSMENT_COMPLETED_TERMINATE = 30;
export const ASSESSMENT_COMPLETED_NO_ACTION_REQUIRED = 5;

export const CORL_CLEARED_QUESTIONNAIRE_ID = 1;
export const CONTROL_BASED_QUESTIONNAIRE_ID = 2;
export const SCOPING_BASED_QUESTIONNAIRE_ID = 8;
export const SUPPLEMENTAL_QUESTIONNAIRE_ID = 7;

export const ASSESSMENT_QUESTIONNAIRE_ANSWERED_QUESTIONS_TYPE_ID = 3;

export const QUESTIONNAIRE_PROGRESS_AREA_CHART_COLORS = [
  "#145091",
  "#0083E9",
  "#855B94",
  "#F5B335",
  "#F78D2A",
]

export const OVERVIEW = 'overview';
export const REQUEST = 'request';
export const RESULTS = 'results';
export const REMEDIATION_GUIDANCE = 'remediation-guidance';
export const ASSESSMENT_FILES = 'assessment-files';

export const ASSESSMENT_DETAILS_SIDEBAR_MENUS = [
  { label: 'OVERVIEW', value: OVERVIEW, faIcon: faGlobe },
  { label: 'REQUEST', value: REQUEST, faIcon: faHand },
  { label: 'RESULTS', value: RESULTS, faIcon: faShield },
  { label: 'ASSESSMENT FILES', value: ASSESSMENT_FILES, faIcon: faScrewdriverWrench },
  { label: 'REMEDIATION GUIDANCE', value: REMEDIATION_GUIDANCE, faIcon: faScrewdriverWrench },
];

export const ASSESSMENT_OWNER_TOOLTIP =
  'Assessment Owners are supplier employees responsible for completing the assessment. Typically a supplier security contact. Only a supplier may update the assessment owner assigned to the assessment.';
export const ENGAGEMENT_OWNER_TOOLTIP =
  'Engagement Owners are supplier employees responsible for the engagement with a buyer. Typically a sales, customer success, or customer experience account manager. Only a client may update the engagement owner assigned to the assessment.';

export const VENDOR_ASSESSMENT_OWNER = 8;
export const VENDOR_ENGAGEMENT_OWNER = 9;

export const PRE_ASSESSMENT_TYPE = 'Pre-Assessment';